import { createGlobalStyle } from "styled-components";
import { rgba } from "polished";
import reset from "styled-reset";
import { theme } from "@styles/theme";

export const GlobalStyle = createGlobalStyle`
    ${reset}

    * {
      box-sizing: border-box;
    }

    html,
    body {
      margin: 0;
      padding: 0;
      white-space: pre-line;
    }

    html {
      font: normal 100% / 1.65 serif;
      scroll-behavior: smooth;
    }

    body {
      hyphens: none;
      word-wrap: break-word;
      color: ${theme.colors.text};
      font-family: "DM Sans", Helvetica, Arial, sans-serif;
      background: #fafafa;
      margin: 0;
    }

    div,
    section,
    article,
    aside,
    main {
      > h1,
      > h2,
      > h3,
      > h4,
      > h5,
      > h6 {
        margin-top: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-rendering: optimizeLegibility;
      font-weight: 500;
      margin-top: 0.625em;
      color: ${theme.colors.text};
      line-height: 1.2;

      &:first-child {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 2.5rem;
      margin-bottom: 1.8rem;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 1.6rem;
    }

    h4 {
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
    }

    h5 {
      font-size: 1.125rem;
      margin-bottom: 0.6rem;
    }

    h6 {
      font-size: 1rem;
      margin-bottom: 0.6rem;
    }

    p {
      font-size: 1rem;
    }

    p,
    ul,
    ol,
    dl,
    pre {
      line-height: 1.5;
      margin-bottom: 1rem;

      &:last-child,
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    fieldset {
      margin: 2rem 0 1rem;

      &:last-child,
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    input,
    select,
    textarea,
    button {
      font-family: inherit;
    }

    small {
      font-size: 0.8em;
      line-height: 1;
      color: ${theme.colors.textLight}
    }

    a {
      color: inherit;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    strong,
    b {
      font-weight: 500;
    }

    em {
      font-style: italic;
    }

    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }

    sup {
      top: -0.5em;
    }

    sub {
      bottom: -0.5em;
    }

    img {
      height: auto;
      width: 100%;
      display: block;
    }

    ul {
      list-style-type: disc;

      ul {
        margin: 0;
      }

      ul > li {
        margin-left: 1.5rem;
      }
    }

    ul,
    ol,
    dl {
      margin-left: 2.5rem;
      font-size: 1em;
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ul ul,
    ol ul {
      list-style-type: circle;
    }

    ol ol ul,
    ol ul ul,
    ul ol ul,
    ul ul ul {
      list-style-type: square;
    }

    ol {
      list-style-type: decimal;
    }

    dl {
      display: grid;
      grid-column-gap: 1.5rem;
      grid-row-gap: 1rem;
      grid-template-columns: max-content auto;
      margin: 0;

      dt {
        font-weight: 500;
        &:after {
          content: ":";
        }
      }
    }

    dd {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-all;
      word-break: break-word;
      hyphens: auto;
    }

    hr {
      display: block;
      border: 0;
      height: 0;
      width: 100%;
      border-bottom: 1px solid ${theme.newColors.background.variant4};
      margin: 1.25em auto;
    }

    blockquote {
      position: relative;
      padding: 0.75rem 1.5rem 0.75rem 1rem;
      font-size: 1.25rem;
      border-left: 0.5rem solid ${theme.colors.text};
      margin: 0 0 1.25em;

      &::before {
        content: '"';
        position: absolute;
        top: 1.45rem;
        left: 0.5rem;
        color: ${theme.colors.text};
        font-size: 4rem;
        line-height: 0.1rem;
        margin-right: 0;
        vertical-align: -0.4rem;
      }

      & p {
        display: inline-block;

        &:first-child {
          text-indent: 1.75rem;
        }

        &:last-child {
          margin: 0 0 0.5rem;
        }
      }
    }

    pre {
      position: relative;
      background: ${theme.colors.white};
      border: 3px solid ${theme.colors.text};
      color: ${theme.colors.text};
      page-break-inside: avoid;
      font-family: monospace;
      font-size: 1em;
      line-height: 1.6;
      max-width: 100%;
      padding: 1em 1.5em;
      display: block;
      word-wrap: break-word;

      code {
        border: none;
        padding: 0;
        font-size: inherit;
        white-space: pre-wrap;
        background-color: transparent;
        color: ${theme.colors.text};
      }
    }

    code {
      background: ${theme.colors.text};
      border: 1px solid ${theme.colors.text};
      padding: 0.1em 0.5em 0.2em;
      color: ${theme.colors.text};
      font-family: monospace;

      &::selection {
        background: #b3d4fc;
      }
    }

    mark {
      color: inherit;
      background: ${rgba(theme.colors.text, 0.5)};
      padding: 0 0.2em;
    }

    abbr {
      position: relative;
      &:hover {
        &::after {
          content: attr(title);
          position: absolute;
          white-space: nowrap;
          padding: 0.25rem 0.5rem;
          background: ${theme.colors.white};
          font-size: 0.75rem;
          border: 1px solid ${theme.colors.text};
          box-shadow: 4px 4px 20px -10px ${rgba(theme.colors.text, 0.75)};
          top: 0;
          left: 50%;
          transform: translate(-50%, calc(-100% - 0.25rem));
        }
      }
    }

    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }

    table {
      width: 100%;
      margin-bottom: -1rem;
    }

    thead {
      & th {
        text-decoration: underline;
      }
    }
    
    th,
    td {
      padding: 0 0.5rem 1rem;
      text-align: center;
      line-height: 1.2;

      &:first-child {
        text-align: left;
        padding-left: 0;
      }

      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
`;

import { ReactElement, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { appWithTranslation, useTranslation } from "next-i18next";
import { GlobalStyle } from "@styles/global";
import { init as FullStoryInit } from "@fullstory/browser";
import { AccessibilityWrapper } from "@components/AccessibilityWrapper";
import { DefaultLayout } from "@components/layouts/Default";
import { useSessionId } from "@hooks/useSessionId";
import { Brand } from "@hooks/useBrand";
import { parseQuery } from "@utils/parseQuery";
import { Providers } from "@components/Providers";
import useScrollPosition from "@react-hook/window-scroll";
import * as Sentry from "@sentry/nextjs";
import { sendEvent } from "@utils/sendMessage";
import { isEnum } from "@utils/isEnum";
import ErrorPage from "./error";

interface ExtendedAppProps extends AppProps {
  err?: Error;
}

const MyApp = ({ Component, pageProps, err }: ExtendedAppProps): ReactElement => {
  const { t } = useTranslation("header");
  const { sessionId, isLoading } = useSessionId();
  const { query } = useRouter();
  const hasFullStoryInit = useRef<boolean>(false);

  // Scroll analytics
  // -- start --
  const scrollY = useScrollPosition();
  const scrollPosition = useRef(0);
  const startTime = useRef(+new Date());
  const previousTime = useRef<number>();
  const markers = useRef([25, 50, 75, 100]);

  const setScrollPosition = useCallback((position: number) => {
    const currentScrollPosition = Math.round(
      (position / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) *
        100
    );

    previousTime.current = previousTime.current || +new Date() - startTime.current;

    if (
      // if current position is greater than previous and its been more than Xms
      (currentScrollPosition > scrollPosition.current &&
        +new Date() - startTime.current - previousTime.current > 750) ||
      // or one of the markers has been hit
      (currentScrollPosition > scrollPosition.current &&
        markers.current.some((marker) => currentScrollPosition >= marker))
    ) {
      // update scroll position
      scrollPosition.current = currentScrollPosition;
      // set new previous time
      previousTime.current = +new Date() - startTime.current;
      // remove any hit markers
      markers.current = markers.current.filter((marker) => marker > currentScrollPosition);
      // log
      window?.analytics.track("Scroll position", {
        category: "Scroll Depth",
        position: scrollPosition.current,
        eventTiming: previousTime.current,
      });
    }
  }, []);

  // When google translate manipulates the DOM do not crash the application
  // Instead log the error allowing us to identify and fix the problem element
  // Solution from https://github.com/facebook/react/issues/11538#issuecomment-417504600
  // ESLint and TS disabled to allow for copy and paste of workaround code
  // Related issue: https://gitlab.com/hokodo/engineering/bnpl-frontend/-/issues/108
  useLayoutEffect(() => {
    if (typeof Node === "function" && Node.prototype) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const originalRemoveChild = Node.prototype.removeChild;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line func-names
      Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
          if (console) {
            console.error("Cannot remove a child from a different parent", child, this);
            Sentry.captureException(new Error("Cannot remove a child from a different parent"), {
              extra: {
                childNode: child,
                this: this,
              },
            });
          }
          return child;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        return originalRemoveChild.apply(this, arguments);
      };

      // eslint-disable-next-line @typescript-eslint/unbound-method
      const originalInsertBefore = Node.prototype.insertBefore;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line func-names
      Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot insert before a reference node from a different parent",
              referenceNode,
              this
            );

            Sentry.captureException(
              new Error("Cannot insert before a reference node from a different parent"),
              {
                extra: {
                  referenceNode,
                  this: this,
                },
              }
            );
          }
          return newNode;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        return originalInsertBefore.apply(this, arguments);
      };
    }
  }, []);

  useEffect(() => {
    setScrollPosition(scrollY);
  }, [scrollY, setScrollPosition]);
  // -- Scroll analytics end --

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG && !hasFullStoryInit.current) {
      FullStoryInit({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG });
      hasFullStoryInit.current = true;
    }

    // Analytics
    window.addEventListener("beforeunload", () => {
      window?.analytics.track("Browser closed, refreshed or history state changed");
    });

    // Resize
    if (query.embedded) {
      const getBodyHeight = (): number => {
        let height = 100;
        let scrollHeight = 100;
        let offsetHeight = 100;

        if (document.body) {
          if (document.body.scrollHeight) {
            scrollHeight = document.body.scrollHeight;
            height = scrollHeight;
          }

          if (document.body.offsetHeight) {
            offsetHeight = document.body.offsetHeight;
            height = offsetHeight;
          }

          if (scrollHeight && offsetHeight) {
            height = Math.max(scrollHeight, offsetHeight);
          }
        }

        return height;
      };

      const resizeObserver = new ResizeObserver(() => {
        sendEvent("resize", {
          height: getBodyHeight(),
        });
      });

      // start observing a DOM node
      resizeObserver.observe(document.body);
    }
  }, [query.embedded]);

  useEffect(() => {
    // NOTE: as ThreatMetrix is loaded in a head script with defer
    // it should exist at this stage unless there was a failure
    if (
      process.env.NEXT_PUBLIC_THREATMETRIX_DOMAIN &&
      process.env.NEXT_PUBLIC_THREATMETRIX_ORG_ID &&
      typeof window.threatmetrix !== "undefined" &&
      sessionId &&
      !isLoading
    ) {
      window.threatmetrix.profile(
        process.env.NEXT_PUBLIC_THREATMETRIX_DOMAIN,
        process.env.NEXT_PUBLIC_THREATMETRIX_ORG_ID || "",
        sessionId
      );
    }
  }, [isLoading, sessionId]);

  const brand = parseQuery(query.design);
  const isBranded = brand && brand !== Brand.DEFAULT;

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Head>
        <title>{t(isBranded ? "pageTitleWithBrand" : "pageTitle", { brand })}</title>
        <meta name="description" content="Trade Credit Powered By Hokodo" />
        <link
          rel="icon"
          href={
            isEnum(Brand)(brand)
              ? `/favicon/${brand.toLocaleLowerCase()}.ico`
              : "favicon/hokodo.ico"
          }
          type="image/x-icon"
        />
      </Head>

      <GlobalStyle />

      <Providers brand={brand}>
        <AccessibilityWrapper>
          <div id="modalPortal" />
          <DefaultLayout>
            {/* NOTE: The spread is needed as component props are unknown */}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...pageProps} err={err} />
          </DefaultLayout>
        </AccessibilityWrapper>
      </Providers>
    </Sentry.ErrorBoundary>
  );
};

export default appWithTranslation(MyApp);

import styled from "styled-components";

interface WrapperProps {
  tabbing: boolean;
}

export default styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  *:focus {
    outline: 0;
    box-shadow: ${({ tabbing }) => (tabbing ? "0 0 2px 2px #51a7e8" : undefined)};
  }
`;

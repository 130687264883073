export const isExternalURL = (url: string): boolean => {
  const host = window.location.hostname;

  const linkHost = (() => {
    if (/^https?:\/\//.test(url)) {
      // Absolute URL.
      // The easy way to parse an URL, is to create <a> element.
      // @see: https://gist.github.com/jlong/2428561
      const parser = document.createElement("a");
      parser.href = url;

      return parser.hostname;
    }
    // Relative URL
    return window.location.hostname;
  })();

  return host !== linkHost;
};

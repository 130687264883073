type ErrorResponse = { message?: string } | string;

export type ErrorLike = {
  response: Response;
  result: ErrorResponse;
  status: number;
} & Error;

export const fetcher = async <Data>(path: string, init?: RequestInit): Promise<Data> => {
  const fetchData = () =>
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL || ""}/${
        process.env.NEXT_PUBLIC_API_VERSION || ""
      }/${path.replace(/^\//, "")}`,
      init
    );

  let response = await fetchData();

  if (response.status === 202) {
    console.info("HTTP 202 received, polling endpoint for 201...");

    let counter = 0;

    const wait = (amount = 0) =>
      new Promise((resolve) => {
        setTimeout(resolve, amount);
      });

    // Loop while the operation is still in progress...
    while (response.status === 202 && counter < 5) {
      // eslint-disable-next-line no-await-in-loop
      response = await fetchData();

      // eslint-disable-next-line no-await-in-loop
      await wait(750);

      counter += 1;

      console.info(`Received status is ${response.status}`);
    }

    if (response.status === 202) {
      const error = new Error("Processing timed out") as ErrorLike;

      error.response = response;
      error.status = response.status;

      throw error;
    }
  }

  if (response.status >= 200 && response.status < 300) {
    try {
      const text = (await response.text()) || "{}"; // As this is a JSON file we need to take the text value first
      return JSON.parse(text) as Data;
    } catch (err) {
      return {} as Data;
    }
  }

  let errorMessage = `Response not successful. Received status code ${response.status}`;
  let parsedErrorResponse: { message?: string } | string;

  try {
    parsedErrorResponse = (await response.clone().json()) as {
      message?: string;
    };

    if (parsedErrorResponse?.message) {
      errorMessage = parsedErrorResponse.message;
    }
  } catch (_error) {
    // its not json
    parsedErrorResponse = await response.clone().text();
  }

  const error = new Error(errorMessage) as ErrorLike;

  error.response = response;
  error.result = parsedErrorResponse;
  error.status = response.status;

  throw error;
};

export const fetcherWithToken =
  <Data>(token: string, init?: RequestInit) =>
  async (url: string): Promise<Data> => {
    const headers: Record<string, string> = {
      authorization: `OrderKey ${token}`,
    };

    if (init?.method !== "" && init?.method !== "GET") {
      headers["content-type"] = "application/json";
    }

    return fetcher<Data>(url, {
      method: "GET",
      headers: new Headers(headers),
      ...init,
    });
  };

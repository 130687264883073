export const localePicker = (
  availableLocals: string[],
  locale: string,
  defaultLocale: string
): string => {
  const locales = new Set(availableLocals.map((temp) => temp.toLowerCase()));

  let newLocale: string | undefined = Array.from(locales).find(
    (currentLocale) => currentLocale === locale
  );

  if (!newLocale) {
    newLocale = Array.from(locales).find(
      (currentLocale) =>
        currentLocale.split("-")[0] === locale || currentLocale === locale.split("-")[0]
    );
  }

  return newLocale || defaultLocale;
};

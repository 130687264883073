import { Brand } from "@hooks/useBrand";
import "styled-components";

export const theme = {
  theme: Brand.DEFAULT,
  mode: "light",
  newColors: {
    primary: {
      variant1: "#132E34",
      variant2: "#41585D",
      variant3: "#718285",
      variant4: "#A1ABAE",
      variant5: "#D0D5D6",
    },
    accent: {
      variant1: "#64CDC8",
      variant2: "#9ED7D5",
      variant3: "#BEE4E3",
    },
    neutral1: {
      variant1: "#C7E6DE",
    },
    neutral2: {
      variant1: "#EDF6F4",
    },
    error: {
      variant1: "#E11900",
    },
    text: {
      variant1: "#132E34",
      variant2: "#757575",
      variant3: "#FFFFFF",
      variant4: "#008489",
    },
    background: {
      variant1: "#FAFAFA",
      variant2: "#FFFFFF",
      variant3: "#000000",
      variant4: "#DDDDDD",
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
      marginBottom: "2rem",
    },
    h2: {
      fontSize: "2.5rem",
      marginBottom: "1.8rem",
    },
    h3: {
      fontSize: "2rem",
      marginBottom: "1.6rem",
    },
    h4: {
      fontSize: "1.5rem",
      marginBottom: "1.2rem",
    },
    h5: {
      fontSize: "1.125rem",
      marginBottom: "0.6rem",
    },
    h6: {
      fontSize: "1rem",
      marginBottom: "0.6rem",
    },
    small: {
      fontSize: "0.875rem",
      lineHeight: 1,
    },
  },
  colors: {
    black: "#333",
    greyLightest: "#F8F8F8",
    greyLight: "#ECEBEB",
    grey: "#dfdfdf",
    greyDark: "#999",
    greyDarkest: "#666",
    white: "#fff",
    text: "#132E34",
    textLight: "#42575d",
    red: "#cc0000",
    orange: "#CA8A2A",
  },
  ratios: {
    level1: "0.25rem",
    level2: "0.5rem",
    level3: "0.75rem",
    level4: "1rem",
    level5: "1.25rem",
    level6: "1.5rem",
    level7: "2rem",
    level8: "2.5rem",
    level9: "3rem",
    level10: "4rem",
    level11: "5rem",
    level12: "6rem",
    level13: "7.5rem",
    level14: "10rem",
  },
  spacing: {
    gap: " clamp(1.3rem, 3.3vw, 1.5rem)",
  },
};

export type Theme = typeof theme;

declare module "styled-components" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

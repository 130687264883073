import { FC, ReactChild, ReactElement } from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Wrapper, { Content, Title, Text } from "./OrderError.style";

interface OrderErrorProps {
  title: string;
  children: ReactChild;
}

export const OrderErrorLoading = (): ReactElement => (
  <Wrapper>
    <Content>
      <header>
        <Title data-testid="error.title">
          <Skeleton height={22.4} width={200} />
        </Title>
      </header>
      <Text data-testid="error.message">
        <Skeleton height={19} count={2} />
      </Text>
    </Content>
  </Wrapper>
);

export const OrderError: FC<OrderErrorProps> = ({ title, children }) => (
  <Wrapper>
    <Content>
      <header>
        <Title data-testid="error.title">{title}</Title>
      </header>
      <Text data-testid="error.message">{children}</Text>
    </Content>
  </Wrapper>
);

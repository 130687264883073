// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import SentryFullStory from "@sentry/fullstory";
import { sentryOptions } from "./sentry.shared.config";

Sentry.init({
  ...sentryOptions,
  integrations: [new SentryFullStory(process.env.NEXT_PUBLIC_SENTRY_ORG)],
});

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const TRACES_SAMPLE_RATE =
  process.env.TRACES_SAMPLE_RATE || process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE || 1;

export const sentryOptions = {
  // TODO: set SENTRY_RELEASE based on tag
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  dsn: SENTRY_DSN,
  enabled: process.env.NODE_ENV === "production",
  environment: (() => {
    switch (process.env.VERCEL_GIT_COMMIT_REF) {
      case "production":
      case "iframe":
        return "Production";
      case "sandbox":
      case "iframe-sandbox":
        return "Sandbox";
      case "master":
      case "iframe-dev":
        return "Development";
      default:
        return "Review";
    }
  })(),
  tracesSampleRate: Number(TRACES_SAMPLE_RATE) || 0,
  denyUrls: [
    // Threatmetrix leaking errors
    /^https:\/\/r\.hokodo\.co/i,
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
};

export enum Status {
  Unpaid = "unpaid",
  Accepted = "accepted",
  Rejected = "rejected",
  PendingReview = "pending_review",
  CustomerActionRequired = "customer_action_required",
  Cancelled = "cancelled",
  PartFulfilled = "part_fulfilled",
  Fulfilled = "fulfilled",
  Reversed = "reversed",
}

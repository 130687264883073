import { OrderError, OrderErrorLoading } from "@containers/OrderError";
import { useBrand } from "@hooks/useBrand";
import { useOrder } from "@hooks/useOrder";
import { useOrderReference } from "@hooks/useOrderReference";
import { Status } from "@models/status";
import { GetServerSideProps, NextPage } from "next";
import { useTranslation } from "next-i18next";
import { SupportMessage } from "@components/SupportMessage";
import * as Sentry from "@sentry/nextjs";
import { brandServerSideProps } from "@utils/brandServerSideProps";

const sentryError = (errMgs: string, severity: Sentry.Severity): void => {
  Sentry.withScope((scope) => {
    scope.setFingerprint(["error page", errMgs]);
    scope.setLevel(severity);
    if ([Sentry.Severity.Error, Sentry.Severity.Fatal].includes(severity)) {
      Sentry.captureException(new Error(errMgs));
    } else {
      Sentry.captureMessage(errMgs);
    }
  });
};

const ErrorPage: NextPage = () => {
  const { t } = useTranslation(["page-error"]);
  const { order, plan, key } = useOrderReference();
  const { data, paymentPlan, isLoading } = useOrder();
  const { brand } = useBrand();

  // Trade Credit Declined
  if (
    (data?.status.toLocaleLowerCase() === Status.Rejected ||
      data?.deferred_payment?.status === Status.Rejected) &&
    !isLoading
  ) {
    sentryError("Rejected", Sentry.Severity.Warning);

    return (
      <OrderError title={t("tradeCreditDeclined.title", { context: brand })}>
        <>
          {t("tradeCreditDeclined.message", { context: brand })}{" "}
          <SupportMessage subject={t("tradeCreditDeclined.subject", { plan, context: brand })} />
        </>
      </OrderError>
    );
  }

  // Payment Plan found but it is no longer valid aka has expired
  if (paymentPlan && new Date(paymentPlan.valid_until) <= new Date() && !isLoading) {
    sentryError("Expired Payment Plan", Sentry.Severity.Warning);

    return (
      <OrderError title={t("expiredPaymentPlan.title", { context: brand })}>
        <>
          {t("expiredPaymentPlan.message")}{" "}
          <SupportMessage subject={t("expiredPaymentPlan.subject", { plan, context: brand })} />
        </>
      </OrderError>
    );
  }

  // Payment Plan not found
  if (data && order && plan && key && !paymentPlan && !isLoading) {
    sentryError("Payment Plan Not Found", Sentry.Severity.Error);

    return (
      <OrderError title={t("paymentPlanNotFound.title", { context: brand })}>
        <>
          {t("paymentPlanNotFound.message", { context: brand })}{" "}
          <SupportMessage subject={t("paymentPlanNotFound.subject", { plan, context: brand })} />
        </>
      </OrderError>
    );
  }

  if (!isLoading) {
    if (!data && order && plan && key) {
      sentryError("Error Retrieving Order", Sentry.Severity.Error);
    } else if (!order && plan && key) {
      sentryError("Missing Order ID within URL", Sentry.Severity.Error);
    } else if (!plan && order && key) {
      sentryError("Missing Plan ID within URL", Sentry.Severity.Error);
    } else if (!key && order && plan) {
      sentryError("Missing Key ID within URL", Sentry.Severity.Error);
    } else if (order && plan && key) {
      sentryError("Error Page", Sentry.Severity.Fatal);
    }

    return (
      <OrderError title={t("default.title", { context: brand })}>
        <>
          {t("default.message", { context: brand })}{" "}
          <SupportMessage subject={t("default.subject", { plan, context: brand })} />
        </>
      </OrderError>
    );
  }

  return <OrderErrorLoading />;
};

export const getServerSideProps: GetServerSideProps = brandServerSideProps;

export default ErrorPage;

import { useBrand, Brand } from "@hooks/useBrand";
import { ReactNode, ReactElement } from "react";
import { Trans, useTranslation } from "next-i18next";
import { LanguageSwitcher } from "@components/LanguageSwitcher";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { useOrder } from "@hooks/useOrder";
import { isEnum } from "@utils/isEnum";
import Image from "next/image";
import { useRouter } from "next/router";
import Wrapper, {
  Header,
  Footer,
  LinkList,
  Banner,
  HeaderContent,
  Logos,
  PlusSymbol,
  TrustedPartner,
  Support,
} from "./Default.style";

interface DefaultLayoutProps {
  children: ReactNode | ReactNode[];
}

const merchantLogos = {
  [Brand.BOX]: {
    src: "box.svg",
    width: 25,
    height: 25,
  },
  [Brand.ANKORSTORE]: {
    src: "ankorstore.svg",
    width: 101.222,
    height: 15,
  },
  [Brand.SHELFNOW]: {
    src: "ShelfNow.svg",
    width: 105,
    height: 14,
  },
  [Brand.CREOATE]: {
    src: "creoate.png",
    width: 77,
    height: 18,
  },
  [Brand.PFS]: {
    src: "pfs.svg",
    width: 81.88,
    height: 25,
  },
};

export const DefaultLayout = ({ children }: DefaultLayoutProps): ReactElement => {
  const { query } = useRouter();
  const { isLoading } = useOrder();
  const { t } = useTranslation(["header", "footer", "common", "component-usp"]);

  const { brand } = useBrand();

  const isBrandWithLogo = isEnum(Brand)(brand) && brand !== Brand.DEFAULT;

  return (
    <>
      {process.env.VERCEL_GIT_COMMIT_REF &&
        !["production", "iframe"].includes(process.env.VERCEL_GIT_COMMIT_REF) &&
        process.env.NODE_ENV !== "development" && (
          <Banner branch={process.env.VERCEL_GIT_COMMIT_REF}>
            {(process.env.VERCEL_GIT_COMMIT_REF || "").includes("sandbox")
              ? "Sandbox"
              : "Development"}{" "}
            Environment - API Version {process.env.NEXT_PUBLIC_API_VERSION}
          </Banner>
        )}

      {/* TODO: logic - has logo = no text or no logo = has text + fetch logo */}
      <Header hasMerchantLogo={isBrandWithLogo} isEmbedded={!!query.embedded}>
        <Wrapper hasPadding isEmbedded={!!query.embedded}>
          <HeaderContent>
            <Logos>
              <svg width="83" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.335 15.5c-3.82 0-6.741-2.848-6.741-7 0-4.151 2.927-7 6.74-7 3.84 0 6.761 2.847 6.761 7 0 4.154-2.922 7-6.76 7Zm0-11.153c-2.13 0-3.486 1.649-3.486 4.154s1.356 4.152 3.486 4.152c2.149 0 3.505-1.649 3.505-4.152 0-2.504-1.356-4.154-3.505-4.154ZM37.07 15.243l-4.026-5.586-1.231 1.347v4.239H28.64V1.757h3.172v5.715l5.07-5.715h3.652l-5.196 5.78 5.53 7.706H37.07Z"
                  fill="#132E34"
                />
                <path
                  d="M47.147 15.5c-3.82 0-6.74-2.848-6.74-7 0-4.151 2.92-7 6.74-7 3.839 0 6.76 2.847 6.76 7 0 4.154-2.921 7-6.76 7Zm0-11.153c-2.13 0-3.485 1.649-3.485 4.154s1.356 4.152 3.485 4.152c2.15 0 3.505-1.649 3.505-4.152 0-2.504-1.356-4.154-3.505-4.154ZM55.454 1.757h5.092c4.32 0 6.99 2.526 6.99 6.744 0 4.217-2.67 6.742-6.99 6.742h-5.092V1.757Zm4.884 10.918c2.483 0 3.926-1.542 3.926-4.197 0-2.611-1.44-4.153-3.923-4.153h-1.709v8.35h1.706ZM75.297 15.5c-3.818 0-6.74-2.848-6.74-7 0-4.151 2.922-7 6.74-7 3.84 0 6.762 2.847 6.762 7 0 4.154-2.922 7-6.762 7Zm0-11.153c-2.128 0-3.484 1.649-3.484 4.154s1.356 4.152 3.484 4.152c2.15 0 3.506-1.649 3.506-4.152 0-2.504-1.357-4.154-3.506-4.154ZM8.764 1.757v5.057H4.382v3.372h4.382v5.057h3.286V1.757H8.764ZM0 15.243h3.286v-5.057H2.191V6.814h1.095V1.757H0v13.486Z"
                  fill="#132E34"
                />
              </svg>
              {/* TODO: Get logo for merchant from BE */}
              {isBrandWithLogo && (
                <>
                  <PlusSymbol>+</PlusSymbol>

                  <Image
                    src={`/images/logos/${merchantLogos[brand].src}`}
                    alt={brand}
                    width={merchantLogos[brand].width}
                    height={merchantLogos[brand].height}
                  />
                </>
              )}
            </Logos>

            <LanguageSwitcher />
          </HeaderContent>
          {!isBrandWithLogo && (
            <TrustedPartner>
              {isLoading ? (
                <Skeleton width={120} height={16} />
              ) : (
                <span>
                  {brand === "default" || !brand ? (
                    t("header:trustedPartner", { context: brand })
                  ) : (
                    <Trans
                      t={t}
                      values={{
                        brand,
                      }}
                      components={{
                        strong: <strong />,
                      }}
                    >
                      header:proudPartnership
                    </Trans>
                  )}
                </span>
              )}
            </TrustedPartner>
          )}
        </Wrapper>
      </Header>

      <Wrapper isEmbedded={!!query.embedded}>{children}</Wrapper>

      {query.embedded && (
        <Wrapper hasPadding isEmbedded={!!query.embedded}>
          <Support>
            {t("common:anyProblemsOrQuestions")}
            <br />
            <Trans
              t={t}
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a />,
              }}
            >
              component-usp:paymentSupport.description
            </Trans>
          </Support>
        </Wrapper>
      )}

      <Wrapper hasPadding isEmbedded={!!query.embedded}>
        <Footer data-testid="defaultLayout.footer">
          <p>{t("footer:copyright", { year: new Date().getFullYear(), context: brand })}</p>
          <LinkList>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("common:links.privacyPolicy", { context: brand })}
              >
                {t("footer:privacyPolicy", { context: brand })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("common:links.terms", { context: brand })}
              >
                {t("footer:termsAndConditions", { context: brand })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={t("common:links.cookiePolicy", { context: brand })}
              >
                {t("footer:cookiePolicy", { context: brand })}
              </a>
            </li>
          </LinkList>
        </Footer>
      </Wrapper>
    </>
  );
};

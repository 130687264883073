import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  position: relative;
`;

export const FlagIconWrapper = styled.span`
  display: block;
  position: relative;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  overflow: hidden;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 27px;
    height: 27px;
  }
`;

export const LanguageSelectionBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: right;
  background: none;
  min-height: 42px; // minimum mobile touch area
  min-width: 42px; // minimum mobile touch area
  padding: 0;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const LanguageSelectionMenu = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  top: calc(100% + 0.25rem);
  right: 0;

  &:before {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    width: 0.8rem;
    height: 0.8rem;
    top: -0.4rem;
    right: 0.9rem;
    transform: rotate(45deg);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }

  &:after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    width: 4rem;
    height: 2rem;
    top: 0;
    right: 0;
  }
`;

export const LanguageSelectionListBox = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    white-space: nowrap;

    &:first-child a {
      border-radius: 4px 4px 0 0;
    }

    &:last-child a {
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const LanguageOption = styled.a`
  display: flex;
  position: relative;
  font-weight: 400;
  z-index: 999;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  text-decoration: none;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.greyLightest};
    text-decoration: none;
  }

  svg {
    margin-left: auto;
  }
`;

export const LanguageOptionWording = styled.span`
  padding-right: 1.5rem;
`;

import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: clamp(1.3rem, 3.3vw, 1.5rem);
  gap: calc(clamp(1.3rem, 3.3vw, 1.5rem) / 2);

  @media (min-width: 768px) {
    padding: clamp(1.3rem, 3.3vw, 1.5rem);
    padding-top: clamp(1.3rem, 3.3vw, 1.5rem);
    gap: clamp(1.3rem, 3.3vw, 1.5rem);
  }
`;

export const Content = styled.section`
  width: 100%;
  text-align: center;
  padding: clamp(1.3rem, 3.3vw, 1.5rem) clamp(1.5rem, 3.5vw, 1.7rem);
  background: ${({ theme }) => theme.newColors.background.variant2};
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  margin: 0 0 0 ${({ theme }) => theme.ratios.level3};
  color: ${({ theme }) => theme.newColors.text.variant1};
`;

export const Text = styled.p`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.ratios.level6};
`;

import { PaymentMethod } from "@models/paymentMethods";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type UsePaymentMethodResponse = {
  paymentMethod?: PaymentMethod;
  previousPaymentMethod?: PaymentMethod;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
  handleResetPaymentMethod: () => void;
};

export const PaymentMethodContext = createContext<UsePaymentMethodResponse>({
  setPaymentMethod: () => {},
  handleResetPaymentMethod: () => {},
});

export const PaymentMethodProvider: FC = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [previousPaymentMethod, setPreviousPaymentMethod] = useState<PaymentMethod>();

  useEffect(() => {
    if (paymentMethod) {
      window?.analytics.track("Payment method selected", paymentMethod);
    }
    setPreviousPaymentMethod(paymentMethod);
  }, [paymentMethod, setPreviousPaymentMethod]);

  const handleResetPaymentMethod = useCallback(() => {
    window?.analytics.track("Payment method deselected", paymentMethod);
    setPaymentMethod(undefined);
  }, [paymentMethod]);

  const value = useMemo(
    () => ({
      paymentMethod,
      previousPaymentMethod,
      setPaymentMethod,
      handleResetPaymentMethod,
    }),
    [handleResetPaymentMethod, paymentMethod, previousPaymentMethod]
  );

  return <PaymentMethodContext.Provider value={value}>{children}</PaymentMethodContext.Provider>;
};

export const usePaymentMethod = (): UsePaymentMethodResponse => useContext(PaymentMethodContext);

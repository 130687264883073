import { ErrorLike, fetcherWithToken } from "@utils/fetcher";
import useSWR from "swr";
import { SessionIdResponse } from "@models/sessionId";
import { useOrderReference } from "@hooks/useOrderReference";

type UseSessionIdResponse = {
  sessionId?: string;
  isLoading: boolean;
  isError?: ErrorLike;
};

export const useSessionId = (): UseSessionIdResponse => {
  const { order, key } = useOrderReference();

  // NOTE: If the order and token exist then a session is needed
  const shouldHaveSession = !!order && !!key;

  const { data, error } = useSWR<SessionIdResponse, ErrorLike>(
    () => (shouldHaveSession ? `/payment/orders/${order}/session_id` : null),
    fetcherWithToken(key),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    sessionId: data?.session_id,
    isLoading: !error && !data && shouldHaveSession,
    isError: error,
  };
};

import { useRouter } from "next/router";
import { isExternalURL } from "@utils/isExternalURL";
import { useRef } from "react";

type UseRedirectResponse = {
  redirect: (redirectPath: string) => Promise<void>;
};

export const useRedirect = (): UseRedirectResponse => {
  const { pathname, replace, asPath } = useRouter();
  const hasBeenCalledRef = useRef(false);

  const redirect = async (redirectPath: string) => {
    if (!hasBeenCalledRef.current) {
      hasBeenCalledRef.current = true;
      if (isExternalURL(redirectPath)) {
        window.location.replace(redirectPath);
      }

      if (redirectPath !== pathname && !isExternalURL(redirectPath)) {
        await replace({
          pathname: redirectPath,
          query: asPath.split("?")[1],
        });
      }
    }
  };

  return {
    redirect,
  };
};

import { createContext, FC, useCallback, useContext, useEffect, useMemo } from "react";
import { setCookie } from "@utils/setCookie";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import { localePicker } from "@utils/localePicker";
import { getCookie } from "@utils/getCookie";
import { useOrder } from "@hooks/useOrder";
import { Brand, useBrand } from "@hooks/useBrand";

const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LANG || "en-GB";

type UseLocaleResponse = {
  locale: string;
  locales: string[];
  switchLocale: (newLocale: string) => Promise<void>;
};

export const LocaleContext = createContext<UseLocaleResponse>({
  locale: defaultLocale,
  locales: [defaultLocale],
  switchLocale: async () => {},
});

enum LocalType {
  USER_BASED = "userBased",
}

export const LocaleProvider: FC = ({ children }) => {
  const {
    replace,
    pathname,
    query,
    locale = defaultLocale,
    locales = [defaultLocale],
  } = useRouter();
  const { data } = useOrder();
  const { brand } = useBrand();

  // Memorize the locale switcher
  const switchLocale = useCallback(
    async (passedLocale: string, persistLocale = true) => {
      // Find the most suitable locale based on the passed locale
      const newLocale = localePicker(locales, passedLocale, defaultLocale);

      // If it is different to the current locale
      if (newLocale.toLocaleLowerCase() !== locale?.toLocaleLowerCase()) {
        // Set sentry tag
        Sentry.setTag("locale", newLocale);

        if (persistLocale) {
          // Set cookie (if possible) for persistent locale
          setCookie("NEXT_LOCALE", newLocale, 365);
        }

        // Create URL structure
        const URL = {
          pathname,
          query,
        };

        // If cookie setting is not possible (e.g. Safari iFrames, which also doesn't allow localStorage)
        // Then set a URL query to not this in a persistent way
        // TODO: consider post message to set  on parent and then pass to iframe
        if (getCookie("NEXT_LOCALE") !== newLocale && persistLocale) {
          URL.query.locale = LocalType.USER_BASED;
        }

        // Replace current history state with new locale and possibly query
        await replace(URL, URL, {
          locale: newLocale,
        });
      }
    },
    [locale, locales, pathname, query, replace]
  );

  useEffect(() => {
    // Get locale cookie (if possible)
    const cookie = getCookie("NEXT_LOCALE");

    // Check if the non cookie based locale is enabled, if not...
    if (query.locale !== LocalType.USER_BASED) {
      if (cookie) {
        // Check if the cookie locale is different to the current locale, if so then switch locale
        void switchLocale(cookie, false);
        return;
      }

      // ** EXPERIMENT **
      // If Ankorstore then use the browser local as the 2nd priority option
      if (brand === Brand.ANKORSTORE) {
        void switchLocale(navigator.language, false);
        return;
      }

      // If there is a payment offer locale and there is no cookie set
      if (data?.payment_offer?.locale) {
        void switchLocale(data.payment_offer.locale, false);
      }
    }
  }, [brand, data?.payment_offer?.locale, locale, query.locale, switchLocale]);

  // Memorize the context data
  const value = useMemo(() => ({ locale, locales, switchLocale }), [locale, locales, switchLocale]);

  // Return the provider
  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

// Provide hook for the locale
export const useLocale = (): UseLocaleResponse => useContext(LocaleContext);

import { useOnClickOutside } from "@hooks/useClickOutside";
import { useLocale } from "@hooks/useLocale";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { FC, useRef, useState } from "react";
import Wrapper, {
  FlagIconWrapper,
  LanguageSelectionBtn,
  LanguageSelectionListBox,
  LanguageSelectionMenu,
  LanguageOption,
  LanguageOptionWording,
} from "./LanguageSwitcher.style";

export const LanguageSwitcher: FC = () => {
  const { locale, locales, switchLocale } = useLocale();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperElem = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  useOnClickOutside(wrapperElem, handleClose);

  const handleDropdownClick = (): void => {
    setIsOpen(!isOpen);
  };

  const handleLocaleSwitch = (newLocale: string) => async () => {
    await switchLocale(newLocale);
    handleClose();
  };

  const getIcon = (localeCode: string) => {
    const country = localeCode.split("-");

    if (country.length === 2) {
      return `/icons/flags/3x2/${country[1].toUpperCase()}.svg`;
    }

    return `/icons/flags/3x2/${country[0].toUpperCase()}.svg`;
  };

  // TODO: Arrow keyboard navigation - focus state and accessibility already applied so min requirements met

  return (
    <Wrapper ref={wrapperElem}>
      <LanguageSelectionBtn
        type="button"
        aria-label={t(`common:languages.supportingText`)}
        aria-expanded={isOpen}
        aria-controls="languagePickerDropdown"
        onClick={handleDropdownClick}
      >
        <span aria-hidden="true">
          <FlagIconWrapper>
            <span>
              <Image src={getIcon(locale)} alt={`${locale} Flag`} layout="fill" />
            </span>
          </FlagIconWrapper>
          {locale.split("-")[0]}
        </span>
      </LanguageSelectionBtn>
      <LanguageSelectionMenu
        aria-describedby="languagePickerDescription"
        id="languagePickerDropdown"
        isOpen={isOpen}
      >
        <p className="sr-only" id="languagePickerDescription">
          {t(`common:languages.supportingText`)}
        </p>

        <LanguageSelectionListBox role="listbox">
          {locales.map((option) => (
            <li key={option}>
              <LanguageOption
                lang={option}
                hrefLang={option}
                href="#"
                role="option"
                aria-selected={option === locale}
                onClick={handleLocaleSwitch(option)}
              >
                <FlagIconWrapper>
                  <span>
                    <Image src={getIcon(option)} alt={`${locale} Flag`} layout="fill" />
                  </span>
                </FlagIconWrapper>
                <LanguageOptionWording>{t(`common:languages.${option}`)}</LanguageOptionWording>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ opacity: option === locale ? 1 : 0 }}
                >
                  <path
                    d="M1.2002 6.95583L3.2162 9.11978C3.28422 9.19357 3.37139 9.25354 3.47115 9.29518C3.57091 9.33682 3.68067 9.35906 3.7922 9.36022C3.90193 9.36128 4.01056 9.34191 4.1102 9.30351C4.20983 9.26512 4.29797 9.20867 4.3682 9.13827L10.8002 2.64026"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </LanguageOption>
            </li>
          ))}
        </LanguageSelectionListBox>
      </LanguageSelectionMenu>
    </Wrapper>
  );
};

enum Events {
  SUCCESS = "success",
  FAILURE = "failure",
  REJECTED = "rejected",
  CANCEL = "cancel",
  RESIZE = "resize",
}

const called = new Set<string>();

export const sendEvent = (event: `${Events}`, payload: unknown = null): void => {
  if (process.env.NODE_ENV !== "production") {
    console.info("--------------- POSTMESSAGE ---------------");
    console.info("EVENT:", event);
    if (payload) console.info("PAYLOAD:", payload);
    console.info("-------------------------------------------");
  }

  if (event === Events.RESIZE || !called.has(event)) {
    window.parent.postMessage(
      JSON.stringify({
        hokodoJs: true,
        frameId: null,
        event: {
          action: event,
          payload,
        },
      }),
      "*"
    );

    called.add(event);

    setTimeout(() => {
      called.delete(event);
    }, 1000);
  }
};

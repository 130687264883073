import React, {
  useCallback,
  ReactElement,
  ReactNode,
  ReactNodeArray,
  useEffect,
  useState,
} from "react";
import Content from "./AccessibilityWrapper.style";

interface AccessibilityWrapperProps {
  children: ReactNode | ReactNodeArray;
}

export const AccessibilityWrapper = ({ children }: AccessibilityWrapperProps): ReactElement => {
  const [tabbing, setTabbing] = useState(false);

  const handleMouseDown = useCallback(() => {
    setTabbing(false);
    window?.removeEventListener("mousedown", handleMouseDown);
  }, [setTabbing]);

  const handleTabbing = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Tab") {
        setTabbing(true);
        window?.removeEventListener("keydown", handleTabbing);
      }
    },
    [setTabbing]
  );

  useEffect(() => {
    let mounted = true;

    if (tabbing) {
      window?.addEventListener("mousedown", () => {
        if (mounted) handleMouseDown();
      });
    } else {
      window?.addEventListener("keydown", (e) => {
        if (mounted) handleTabbing(e);
      });
    }

    return () => {
      mounted = false;
      window?.removeEventListener("mousedown", handleMouseDown);
      window?.removeEventListener("keydown", handleTabbing);
    };
  }, [tabbing, handleMouseDown, handleTabbing]);

  return (
    <Content
      data-testid="accessibilityWrapper"
      tabbing={tabbing}
      className={tabbing ? "tabbing" : ""}
    >
      {children}
    </Content>
  );
};

import styled from "styled-components";

export default styled.div<{ hasPadding?: boolean; isEmbedded: boolean }>`
  display: flex;
  width: 100%;
  max-width: ${({ isEmbedded }) => (isEmbedded ? "832px" : "1132px")};
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  flex: 1;
  padding: ${({ hasPadding }) => hasPadding && "0 clamp(1.3rem, 3.3vw, 1.5rem)"};
`;

export const MainContent = styled.div``;

interface BannerProps {
  branch?: string;
}

export const Banner = styled.div<BannerProps>`
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  padding: 1rem;
  color: ${({ branch, theme }) =>
    branch?.includes("sandbox") ? theme.newColors.text.variant3 : theme.newColors.text.variant1};
  background: ${({ branch, theme }) =>
    branch?.includes("sandbox")
      ? theme.newColors.primary.variant1
      : theme.newColors.accent.variant1};

  @media only percy {
    display: none;
  }
`;

export const Header = styled.header<{ hasMerchantLogo: boolean; isEmbedded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 56px;
  padding: ${({ hasMerchantLogo }) => !hasMerchantLogo && `0 0 0.6rem`};
  z-index: 998;
  background: ${({ theme, isEmbedded }) => !isEmbedded && theme.newColors.background.variant2};
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlusSymbol = styled.span`
  margin: 0 0.25rem;
  color: ${({ theme }) => theme.newColors.primary.variant2};
`;

export const TrustedPartner = styled.p`
  margin-top: -0.6rem;
  font-size: ${({ theme }) => theme.typography.small.fontSize};
`;

export const Support = styled.p`
  text-align: center;
  padding: clamp(1.3rem, 3.3vw, 1.5rem);
  padding-top: 0;
  color: ${({ theme }) => theme.newColors.text.variant2};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
`;

export const Footer = styled.footer`
  padding: clamp(1.3rem, 3.3vw, 1.5rem) 0;
  border-top: 1px solid ${({ theme }) => theme.newColors.background.variant4};
  text-align: center;
  color: ${({ theme }) => theme.newColors.text.variant2};
  font-size: ${({ theme }) => theme.typography.small.fontSize};

  p {
    font-size: ${({ theme }) => theme.typography.small.fontSize};
  }
`;

export const LinkList = styled.ul`
  margin: ${({ theme }) => theme.ratios.level2} ${({ theme }) => `-${theme.ratios.level2}`} 0;
  list-style-type: none;

  li {
    display: inline-block;
  }

  a {
    margin: 0 ${({ theme }) => theme.ratios.level2};
    color: ${({ theme }) => theme.newColors.text.variant2};
    font-weight: 400;
  }
`;

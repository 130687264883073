import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from "react";

export enum Brand {
  BOX = "Box",
  ANKORSTORE = "Ankorstore",
  SHELFNOW = "ShelfNow",
  CREOATE = "CREOATE",
  PFS = "Paris Fashion Shop",
  DEFAULT = "default",
}

type BrandProviderProps = {
  brand: string;
};

type UseBrandResponse = {
  brand: string;
  setBrand: Dispatch<SetStateAction<string>>;
};

export const BrandContext = createContext<UseBrandResponse>({
  brand: Brand.DEFAULT,
  setBrand: () => {},
});

export const BrandProvider: FC<BrandProviderProps> = ({ children, brand: initialBrand }) => {
  const [brand, setBrand] = useState(initialBrand);

  const value = useMemo(() => ({ brand, setBrand }), [brand]);

  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
};

export const useBrand = (): UseBrandResponse => useContext(BrandContext);

import { ReactElement } from "react";
import { Trans, useTranslation } from "next-i18next";
import { useBrand } from "@hooks/useBrand";

type SupportMessageProps = {
  subject?: string;
  error?: boolean;
};

export const SupportMessage = ({ subject = "", error }: SupportMessageProps): ReactElement => {
  const { t } = useTranslation(["common"]);
  const { brand } = useBrand();

  return (
    <Trans
      t={t}
      values={{
        brand,
        supportEmail: t("common:supportEmail", { context: brand }),
        supportContactNumber: t("common:supportContactNumber", { context: brand }),
      }}
      tOptions={{
        context: brand,
      }}
      components={{
        emailLink: (
          <a href={`mailto:${t("common:supportEmail", { context: brand })}?subject=${subject}`}>
            {t("common:supportEmail", { context: brand })}
          </a>
        ),
        telLink: (
          <a
            href={`tel:${t("common:supportContactNumber", { context: brand }).replace(/\s/g, "")}`}
          >
            {t("common:supportContactNumber", { context: brand })}
          </a>
        ),
      }}
    >
      {error
        ? "common:supportContactInformation.error"
        : "common:supportContactInformation.default"}
    </Trans>
  );
};

import { BrandProvider } from "@hooks/useBrand";
import { LocaleProvider } from "@hooks/useLocale";
import { PaymentMethodProvider } from "@hooks/usePaymentMethod";
import { theme } from "@styles/theme";
import { fetcher } from "@utils/fetcher";
import { FC } from "react";
import { ThemeProvider } from "styled-components";
import { SWRConfig } from "swr";

type ProviderProps = {
  brand: string;
};

export const Providers: FC<ProviderProps> = ({ children, brand }) => (
  <BrandProvider brand={brand}>
    <ThemeProvider theme={theme}>
      <SWRConfig
        value={{
          fetcher,
        }}
      >
        <PaymentMethodProvider>
          <LocaleProvider>{children}</LocaleProvider>
        </PaymentMethodProvider>
      </SWRConfig>
    </ThemeProvider>
  </BrandProvider>
);

import { Brand, useBrand } from "@hooks/useBrand";
import { useOrderReference } from "@hooks/useOrderReference";
import { Data } from "@models/data";
import { PaymentOffer } from "@models/paymentOffer";
import { PaymentPlan, PaymentPlans } from "@models/paymentPlans";
import { Status } from "@models/status";
import { ErrorLike, fetcherWithToken } from "@utils/fetcher";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import useSWR, { SWRResponse } from "swr";
import * as Sentry from "@sentry/nextjs";
import { useRedirect } from "@hooks/useRedirect";
import { sendEvent } from "@utils/sendMessage";

type UseOrderResponse = {
  data?: Data;
  error?: ErrorLike;
  organisationId?: string;
  paymentPlans?: PaymentPlans;
  paymentPlan?: PaymentPlan;
  isLoading: boolean;
  mutate: SWRResponse<Data, ErrorLike>["mutate"];
};

// TODO: provide failure reason

export const useOrder = (): UseOrderResponse => {
  const {
    pathname,
    query: { showPaymentOffers },
  } = useRouter();
  const { order, key, plan } = useOrderReference();
  const { brand } = useBrand();
  const { redirect } = useRedirect();
  const { replace, query } = useRouter();
  const isErrorRoute = ["/404", "/500"].includes(pathname);

  const hasNeededQueries = !!(order && key);
  const apiPath = `/payment/orders/${order}/?expand=customer,payment_offer,deferred_payment`;

  // TODO: find a better way to handle this for telesales
  const errorRedirectPathGenerator = useCallback(
    (payment_offer?: PaymentOffer | null) =>
      brand === Brand.BOX || query.embedded ? "/error" : payment_offer?.urls.failure || "/error",
    [brand, query.embedded]
  );

  // TODO: find a better way to handle this for telesales
  const successRedirectPathGenerator = useCallback(
    (payment_offer: PaymentOffer | null) =>
      brand === Brand.BOX ? "/confirmation" : payment_offer?.urls.success || "/confirmation",
    [brand]
  );

  const findMatchingPlan = (
    paymentPlans?: PaymentPlans,
    planId?: string
  ): undefined | PaymentPlan =>
    paymentPlans && planId ? paymentPlans.find((p) => p.id === planId) : undefined;

  const parseData = useCallback(
    async (data: Data): Promise<Data> => {
      // Set Sentry context
      Sentry.setContext("organisation", { id: data.customer.organisation.id });
      Sentry.setContext("company", { id: data.customer.organisation.company.id });
      Sentry.setUser({
        id: data.customer.user.id,
        email: data.customer.user.email,
      });

      // Get the merchants error and success paths
      const errorRedirectPath = errorRedirectPathGenerator(data.payment_offer);
      const successRedirectPath = successRedirectPathGenerator(data.payment_offer);

      const isSuccessStatus = (status?: Status): boolean => {
        if (!status) {
          return false;
        }

        return [
          Status.Accepted,
          Status.PendingReview,
          Status.CustomerActionRequired,
          Status.PartFulfilled,
          Status.Fulfilled,
        ].includes(status.toLocaleLowerCase() as Status); // defensive encase BE change case
      };

      const isFailureStatus = (status?: Status): boolean => {
        if (!status) {
          return false;
        }

        return [Status.Rejected, Status.Cancelled, Status.Reversed].includes(
          status.toLocaleLowerCase() as Status
        ); // defensive encase BE change case
      };

      // If there is a deferred payment with a success status
      if (!!data.deferred_payment && isSuccessStatus(data.deferred_payment.status)) {
        if (query.embedded) {
          sendEvent("success");
        } else if (pathname !== successRedirectPath) {
          await redirect(successRedirectPath);
        }
        if (!query.plan) {
          await replace(
            {
              pathname,
              query: {
                ...query,
                plan: data.deferred_payment.payment_plan,
                showPaymentOffers: true,
              },
            },
            {
              pathname,
              query: {
                ...query,
                plan: data.deferred_payment.payment_plan,
                showPaymentOffers: true,
              },
            },
            {
              shallow: true,
            }
          );
        }
      }

      // If they are on the confirmation screen without a deferred payment then redirect back to root
      if (!data.deferred_payment && pathname === successRedirectPath) {
        await redirect("/");
      }

      // If they are rejected then send error event and redirect to the error screen
      if (
        (isFailureStatus(data.status) || isFailureStatus(data.deferred_payment?.status)) &&
        pathname !== errorRedirectPath
      ) {
        if (!isErrorRoute) {
          sendEvent("rejected", {
            message: `Status - ${data.deferred_payment?.status || data.status}`,
          });
          await redirect(errorRedirectPath);
          return {
            ...data,
            payment_offer: null,
          };
        }
      }

      // If there are no payment offers, thus rejected, then redirect to the error screen
      if (!data.payment_offer?.offered_payment_plans?.length && pathname !== errorRedirectPath) {
        if (!isErrorRoute) {
          sendEvent("rejected", {
            message: "No payment offers",
          });
          await redirect(errorRedirectPath);
          return {
            ...data,
            payment_offer: null,
          };
        }
      }

      // If we are NOT showing all the payment offer options + there is a plan or deferred payment plan
      if (!showPaymentOffers || plan || data.deferred_payment?.payment_plan) {
        const matchedPaymentPlan = findMatchingPlan(
          data.payment_offer?.offered_payment_plans,
          data.deferred_payment?.payment_plan || plan
        );

        // If there is no matching payment offer
        if (!matchedPaymentPlan) {
          if (!isErrorRoute) {
            sendEvent("failure", {
              message: "No matching payment offer",
            });
            await redirect(errorRedirectPath);
            return {
              ...data,
              payment_offer: null,
            };
          }
        }

        // If there is a matched plan but the plan has a valid until in the past, aka is expired
        if (
          matchedPaymentPlan &&
          new Date(matchedPaymentPlan.valid_until) <= new Date() &&
          pathname !== errorRedirectPath
        ) {
          if (!isErrorRoute) {
            sendEvent("failure", {
              message: "Payment plan has expired",
            });
            await redirect(errorRedirectPath);
            return {
              ...data,
              payment_offer: null,
            };
          }
        }

        // If there is a plan query and a matching payment plan then send the context to Sentry
        if (matchedPaymentPlan) {
          Sentry.setContext("Payment plan", matchedPaymentPlan);
        }
      }

      // If we are showing payment offer options
      if (showPaymentOffers) {
        // Filter the payment plans to only have the payment plans with a valid until date
        const offerPaymentPlans =
          data.payment_offer?.offered_payment_plans?.filter(
            (paymentPlan) => new Date(paymentPlan.valid_until) >= new Date()
          ) || [];

        // If there are payment offers with valid dates, then return only those valid payment offers
        if (data.payment_offer?.offered_payment_plans && offerPaymentPlans.length) {
          return {
            ...data,
            payment_offer: {
              ...data.payment_offer,
              offered_payment_plans: offerPaymentPlans,
            },
          };
        }

        // If there are no payment offers with valid dates
        if (!offerPaymentPlans.length && pathname !== errorRedirectPath && !isErrorRoute) {
          sendEvent("failure", {
            message: "All payment plans have expired",
          });
          await redirect(errorRedirectPath);
          return {
            ...data,
            payment_offer: null,
          };
        }
      }

      // Finally return the data
      return data;
    },
    [
      errorRedirectPathGenerator,
      isErrorRoute,
      pathname,
      plan,
      query,
      redirect,
      replace,
      showPaymentOffers,
      successRedirectPathGenerator,
    ]
  );

  const { data, mutate, error } = useSWR<Data, ErrorLike>(
    () => (hasNeededQueries ? apiPath : null),
    async (url: string) => {
      const res = await fetcherWithToken<Data>(key)(url);
      return parseData(res);
    },
    {
      onError: async (err) => {
        Sentry.captureException(err);

        try {
          await Sentry.flush(2000);
        } catch (sentryFlushErr) {
          console.error("Sentry failed to complete network requests");
        }

        sendEvent("failure", {
          message: "Application error",
        });
        void redirect(errorRedirectPathGenerator(data?.payment_offer));
      },
    }
  );

  const mutateWithDataParser = async (...args: Parameters<typeof mutate>) => {
    const [newDataRaw, ...rest] = args;

    if (newDataRaw) {
      if (typeof newDataRaw === "function") {
        const newData = await newDataRaw(data);
        return newData ? mutate(parseData(newData), ...rest) : mutate(...args);
      }

      const newData = await newDataRaw;
      return mutate(parseData(newData), ...rest);
    }

    return mutate(...args);
  };

  useEffect(() => {
    if (!hasNeededQueries) {
      void (async () => {
        if (!isErrorRoute) {
          sendEvent("failure", {
            message: "Incorrect URL structure",
          });
          await redirect(errorRedirectPathGenerator(data?.payment_offer));
        }
      })();
    }
  }, [data?.payment_offer, errorRedirectPathGenerator, hasNeededQueries, isErrorRoute, redirect]);

  return {
    data,
    error,
    organisationId: data?.customer.organisation.id,
    paymentPlans: data?.payment_offer?.offered_payment_plans,
    paymentPlan: findMatchingPlan(
      data?.payment_offer?.offered_payment_plans,
      data?.deferred_payment?.payment_plan || plan
    ),
    isLoading: hasNeededQueries && !error && !data,
    mutate: mutateWithDataParser,
  };
};

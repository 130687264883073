import { parseQuery } from "@utils/parseQuery";
import { useRouter } from "next/router";

type UseOrderReferenceResponse = {
  order: string;
  key: string;
  plan: string;
};

export const useOrderReference = (): UseOrderReferenceResponse => {
  const {
    query: { order, key, plan },
  } = useRouter();

  return {
    order: parseQuery(order),
    key: parseQuery(key),
    plan: parseQuery(plan),
  };
};
